import locale77427e78 from '../../lang/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"us_et","numberFormats":{"nl_et":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"fr_et":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"us_et":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"nl_et","file":"nl.js","iso":"nl","defaultCurrency":"EUR"},{"code":"fr_et","file":"fr.js","iso":"fr","defaultCurrency":"EUR"},{"code":"de_et","file":"de.js","iso":"de","defaultCurrency":"EUR"},{"code":"us_et","file":"en.js","iso":"us","defaultCurrency":"EUR"}],
  defaultLocale: "us_et",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/data/web/eratua/vsf/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true,"redirectOn":"root"},
  differentDomains: false,
  seo: true,
  baseUrl: "https://www.eratua.com/",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "NL",
  normalizedLocales: [{"code":"nl_et","file":"nl.js","iso":"nl","defaultCurrency":"EUR"},{"code":"fr_et","file":"fr.js","iso":"fr","defaultCurrency":"EUR"},{"code":"de_et","file":"de.js","iso":"de","defaultCurrency":"EUR"},{"code":"us_et","file":"en.js","iso":"us","defaultCurrency":"EUR"}],
  localeCodes: ["nl_et","fr_et","de_et","us_et"],
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'nl.js': () => import('../../lang/nl.js' /* webpackChunkName: "lang-nl.js" */),
  'fr.js': () => import('../../lang/fr.js' /* webpackChunkName: "lang-fr.js" */),
  'de.js': () => import('../../lang/de.js' /* webpackChunkName: "lang-de.js" */),
}
